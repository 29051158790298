//#region Imports

import { log } from './ServerCalls/Public/Monitoring/DebugMessages/DebugMessages';
import { ApiResponse } from '../types/api-response';

//#endregion Imports

//#region Methods

export async function logMonitoring(message: string, logNamespace: string ): Promise<ApiResponse> {
  try {
    //todo add MessageGravity enum
    if(process.env.REACT_APP_LOG_DEBUG === "true")
    {
      const response = log(message, 2, logNamespace, new Date().toUTCString());
      return response;
    }
    
    return { isOk: true, message: '', data: '', total: 0 };
    
  } catch (error) {
    console.error("Error in logMonitoring: ", error);
    return { isOk: false, message: 'Request of log failed!', data: '', total: 0 };
  }
}

//#endregion Methods