//#region Imports

// import 'devextreme/dist/css/dx.common.css';//
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';
import React from 'react';
import { HashRouter as Router, useLocation } from 'react-router-dom';
//import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';

import 'devexpress-gantt/dist/dx-gantt.css';
import './styles.scss';
import './theme/theme';
import { useThemeContext, ThemeContext } from './theme/theme';

import { logMonitoring } from './api/monitoring';
//#endregion   Imports

//#region Methods

function RootApp() {
  const { user, loading } = useAuth();
  const isLoginForm = [
    'login',
    'reset-password',
    'create-account',
  ].includes(useLocation().pathname.substring(1));

  logMonitoring(`userEmail: ${user?.email} loading: ${loading} isLoginForm: ${isLoginForm}`, "App.RootApp()");

  if (loading) {
    return <LoadPanel visible />;
  }

  if (user && !isLoginForm) {
    logMonitoring(`userEmail: ${user?.email} loading: ${loading} isLoginForm: ${isLoginForm}`, "App.RootApp() -> Content");
    return <Content />;
  }

  logMonitoring(`userEmail: ${user?.email} loading: ${loading} isLoginForm: ${isLoginForm}`, "App.RootApp() -> UnauthenticatedContent");
  return <UnauthenticatedContent />;
}

//#endregion Methods

//#region HTML & Components

export const App = () => {
  
  const screenSizeClass = useScreenSizeClass();
  const themeContext = useThemeContext();

  logMonitoring(`[INIT Client] screenSizeClass: ${screenSizeClass} isLoaded: ${themeContext.isLoaded} theme: ${themeContext.theme}`, "App.App()");

  return (
    <Router>
      <ThemeContext.Provider value={themeContext}>
        <AuthProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              {
                themeContext.isLoaded ? <RootApp /> : ''
              }
            </div>
          </NavigationProvider>
        </AuthProvider>
      </ThemeContext.Provider>
    </Router>
  );
};

//#endregion HTML & Components