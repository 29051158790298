import { ComparisonOperator } from '../types/ComparisonOperator';  // Assuming the enum is in a separate file

export class DynamicGet {
    public page: string;
    public criterias: Array<[string, string, ComparisonOperator]>;
    public pageSize: number;
    public pageIndex: number;
    public orderByColumns: Record<string, boolean>;
    public includeColumns: Array<string>;
    public dxGridLoadOptions: any;

    constructor() {
        this.page = '';
        this.criterias = [];
        this.pageSize = 0;
        this.pageIndex = 0;
        this.orderByColumns = {};
        this.includeColumns = [];
        this.dxGridLoadOptions = null;
    }
}