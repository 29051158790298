import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import {  login as sendSignInRequest } from '../api/ServerCalls/Public/Account/Account';
import {  validate2fa as validate2faRequest } from '../api/ServerCalls/Public/Account/Account';
import type { User, AuthContextType } from '../types';
import { logMonitoring } from '../api/monitoring';
import { LoginResponse } from '../utils/LoginResponse';

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);

  logMonitoring(` user: ${user} loading: ${loading}`, "AuthProvider()");

  const signIn = useCallback(async (email: string, password: string, rememberMe: boolean) => {
    setLoading(true);
    const result = await sendSignInRequest (email, password, rememberMe);
    logMonitoring(` email: ${email} password: ${password} rememberMe: ${rememberMe} RESPONSE: ${result}`, "AuthProvider().signIn()");
    if (result.isOk) {
      setUser(result.data);
    }
    setLoading(false);
    return result;
  }, []);

  const validate2fa = useCallback(async (userId: string, token: string) => {
    setLoading(true);
    const result = await validate2faRequest (userId, token);
    logMonitoring(` userId: ${userId} token: ${token} RESPONSE: ${result}`, "AuthProvider().signIn()");
    if (result.isOk) {
      setUser(result.data);
    }
    setLoading(false);
    return result;
  }, []);

  const signOut = useCallback(() => {
    setLoading(true);
    logMonitoring(``, "AuthProvider().signOut()");
    setUser(undefined);
    setLoading(false);
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, validate2fa, loading }} {...props} />
  );
}

const AuthContext = createContext<AuthContextType>({ loading: false } as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
