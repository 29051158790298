import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import './DebugTrace.scss';

import DataGrid, { Sorting, Selection, HeaderFilter,
   Scrolling, SearchPanel, ColumnChooser, Export,
    Column, Toolbar, Item, LoadPanel, DataGridTypes
  , ColumnFixing, FilterRow, GroupPanel, RequiredRule,
  Editing,Grouping, RemoteOperations, Pager,
  Paging
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { DynamicGet } from '../../utils/DynamicGet';
import { getApi } from '../../api/ServerCalls/Authorized/Generic/Dynamic';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import 'devextreme/data/odata/store';


const exportFormats = ['xlsx', 'pdf'];

const pageSizes = [10, 25];

export const DebugTrace = () => {
  const [pageSize, setPageSize] = useState(10);
  const [dbDynamicGet, setDbDynamicGet] = useState(new DynamicGet());

  useMemo(() => {
    dbDynamicGet.page = '58';
    dbDynamicGet.pageSize = pageSize;
    dbDynamicGet.pageIndex = 1;
  }, [dbDynamicGet]);


  const store = new CustomStore({
    key: 'Id',
    async load(loadOptions) {

      dbDynamicGet.dxGridLoadOptions = loadOptions;
   
      const result = await getApi(dbDynamicGet);
      return {
        data: result.data,
        totalCount: result.total
      };
    },
  });
 

return (
    //todo changename
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          dataSource={store}
          remoteOperations={true}
          allowColumnReordering={true}
          showBorders={true}
          columnAutoWidth={true}


          className='grid theme-dependent' 
        >
          <Paging
                  defaultPageSize={10}
                  />
          <Pager
            showInfo={true}
            allowedPageSizes={pageSizes}
            showPageSizeSelector={true}
            showNavigationButtons={true}
          />
          <ColumnFixing enabled={true} />
          <ColumnChooser enabled={true} />
          <Sorting mode="multiple" />
          <Column
            dataField='Id'
            dataType="number"// 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
            //fixed={true} // works with ColumnFixing
            //visible={true} // works with ColumnChooser
            //sortOrder="asc" |  sortIndex =1 works with Sorting
            //width="100%"
          />
          <Column
            dataField='Source'
            dataType="string"
          />
          <Column
            dataField='Gravity'
            dataType="string"
          />
          <Column
            dataField='Id_SysApi'
            dataType="number"
          />
          <Column
            dataField='Message'
            dataType="string"
            width="100%"
          />
          <Column
            dataField='Namespace'
            dataType="string"
          />
          <Column
            dataField='ClientTimestamp'
            dataType="datetime"
          />
          <Column
            dataField='ServerTimestamp'
            dataType="datetime"
          />
          </DataGrid>
      </div>
    </div>
  );
};
