//#region Imports

import React, { useState, useRef, useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { LoginOauth } from '../login-oauth/LoginOauth';
import Button, { ButtonTypes } from 'devextreme-react/button';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useAuth } from '../../contexts/auth';
import { ThemeContext } from '../../theme/theme';

import './LoginForm.scss';
import { logMonitoring } from '../../api/monitoring';

//#endregion Imports

function getButtonStylingMode(theme: string | undefined): ButtonTypes.ButtonStyle {
  return theme === 'dark' ? 'outlined' : 'contained';
}

export default function LoginForm() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '', rememberMe: false });
  const themeContext = useContext(ThemeContext);

  logMonitoring(`loading: ${loading} formData?.current: ${formData?.current} themeContext: ${themeContext?.theme}` , "LoginForm()");

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    const { email, password, rememberMe } = formData.current;
    setLoading(true);

    const result = await signIn(email, password, rememberMe);
    logMonitoring(`` , "LoginForm().signIn()");
    if (result.isOk) {
        logMonitoring(`navigate('/Home-Home')` , "LoginForm().signIn()");
        navigate('/Home-Home');
    } else {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
  }, [signIn]);

  const onCreateAccountClick = useCallback(() => {
    logMonitoring(`navigate('/create-account')` , "LoginForm().onCreateAccountClick()");
    navigate('/create-account');
  }, [navigate]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form 
        formData={formData.current}
        disabled={loading}
        showColonAfterLabel
        showRequiredMark={false}>
        <Item 
          dataField='email'
          editorType='dxTextBox' 
          editorOptions={emailEditorOptions}>
            <RequiredRule message='Email is required' />
            <EmailRule message='Email is invalid' />
            <Label visible={false} />
        </Item>
        <Item 
          dataField='password' 
          editorType='dxTextBox' 
          editorOptions={passwordEditorOptions}>
            <RequiredRule message='Password is required' />
            <Label visible={false} />
        </Item>
        <Item 
          dataField='rememberMe' 
          editorType='dxCheckBox' 
          editorOptions={rememberMeEditorOptions}>
            <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width='100%' type='default' useSubmitBehavior={true}>
            <span className='dx-button-text'>{loading ? <LoadIndicator width='24px' height='24px' visible={true} /> : 'Sign In'}</span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
      <div className='reset-link'>
        <Link to={'/reset-password'}>Forgot password?</Link>
      </div>

      <Button
        className='btn-create-account'
        text='Create an account'
        width='100%'
        onClick={onCreateAccountClick}
        stylingMode={getButtonStylingMode(themeContext?.theme)}
      />
      
      <LoginOauth />
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
