//#region Imports

import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginOauth } from '../login-oauth/LoginOauth';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, CustomRule, EmailRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { register as registerAccount } from '../../api/ServerCalls/Public/Account/Account';
import { ValidationCallbackData } from 'devextreme-react/common';
import './CreateAccountForm.scss';
import { logMonitoring } from '../../api/monitoring';
//#endregion Imports

export default function CreateAccountForm() {
  
  //#region Declarations

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', userName: '', firstName: '', lastName: '', password: '' });

  logMonitoring(`formData: ${formData} ` , "CreateAccountForm()");

  //#endregion Declarations

  //#region Methods

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    const { email, userName, firstName, lastName,  password } = formData.current;
    setLoading(true);

    const result = await registerAccount(firstName, lastName, email, userName, password);
    logMonitoring(`email: ${email} userName: ${userName} firstName: ${firstName} lastName: ${lastName} password: ${password} result: ${result}` , "CreateAccountForm().onSubmit()");
    setLoading(false);

    if (result?.isOk) {
      notify(result.message, 'success', 5 * 1000);//todo read from setting
      logMonitoring(`navigate('/login')` , "CreateAccountForm().onSubmit()");
      navigate('/login');
    } else {
      notify(result.message, 'error', 5 * 1000);//todo read from setting
    }
  }, [navigate]);

  //#endregion Methods

  //#region Validations
  
  const anyInputMaxLength = useCallback(
    ({ value }: ValidationCallbackData) => value?.length <= 256 ?? false,
    []
  );

  const anyInputMinLength = useCallback(
    ({ value }: ValidationCallbackData) => value?.length >= 6 ?? false,
    []
  );

  const anyInputMinOneAlphaNumeric = useCallback(
    ({ value }: ValidationCallbackData) => /\W|_/.test(value ?? '') === true,
    []
  );
  
  const anyInputMinOneDigit = useCallback(
    ({ value }: ValidationCallbackData) => /\d/.test(value ?? '') === true,
    []
  );

  const anyInputMinOneUpperCase = useCallback(
    ({ value }: ValidationCallbackData) => /[A-Z]/.test(value ?? '') === true,
    []
  );
  
  const confirmPassword = useCallback(
    ({ value }: ValidationCallbackData) => value === formData.current.password,
    []
  );

  //#endregion Validations

  //#region HTML & Components
  
  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <CustomRule
            message={'Email is too long'}
            validationCallback={anyInputMaxLength}
          />
          <Label visible={true} />
        </Item>
        <Item
          dataField={'userName'}
          editorType={'dxTextBox'}
          editorOptions={userNameEditorOptions}
        >
          <RequiredRule message="User name is required" />
          <CustomRule
            message={'User name is too long'}
            validationCallback={anyInputMaxLength}
          />
          <Label visible={true} />
        </Item>
        <Item
          dataField={'firstName'}
          editorType={'dxTextBox'}
          editorOptions={firstNameEditorOptions}
        >
          <RequiredRule message="First name is required" />
          <CustomRule
            message={'First name is too long'}
            validationCallback={anyInputMaxLength}
          />
          <Label visible={true} />
        </Item>
        <Item
          dataField={'lastName'}
          editorType={'dxTextBox'}
          editorOptions={lastNameEditorOptions}
        >
          <RequiredRule message="Last name is required" />
          <CustomRule
            message={'Last name is too long'}
            validationCallback={anyInputMaxLength}
          />
          <Label visible={true} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords must be at least 6 characters'}
            validationCallback={anyInputMinLength}
          />
          <CustomRule
            message={'Passwords must have at least one non alphanumeric character'}
            validationCallback={anyInputMinOneAlphaNumeric}
          />
          <CustomRule
            message={'Passwords must have at least one digit (\'0\'-\'9\')'}
            validationCallback={anyInputMinOneDigit}
          />
          <CustomRule
            message={'Passwords must have at least one uppercase (\'A\'-\'Z\')'}
            validationCallback={anyInputMinOneUpperCase}
          />
          <Label visible={true} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={true} />
        </Item>
        <Item>
          <div className='policy-info'>
            By creating an account, you agree to the <Link to="#">Terms of Service</Link> and <Link to="#">Privacy Policy</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Create a new account'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>

      <div className='login-link'>
        Have an account? <Link to={'/login'}>Sign In</Link>
      </div>
      <LoginOauth />
    </form>
  );

  //#endregion  HTML & Components
}

//#region Declarations

const emailEditorOptions = { stylingMode: 'filled', placeholder: '', mode: 'email', value: '' };
const userNameEditorOptions = { stylingMode: 'filled', placeholder: '', mode: 'text', value: '' };
const firstNameEditorOptions = { stylingMode: 'filled', placeholder: '', mode: 'text', value: '' };
const lastNameEditorOptions = { stylingMode: 'filled', placeholder: '', mode: 'text', value: '' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: '', mode: 'password', value: '' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: '', mode: 'password', value: '' };

//#endregion Declarations