//#region Imports

import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import Form, { ButtonItem, ButtonOptions } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { confirmation } from '../../api/ServerCalls/Public/Account/Account';
import './ConfirmAccountForm.scss';
import { logMonitoring } from '../../api/monitoring';
//#endregion Imports

export default function ConfirmAccountForm() {
  
  //#region Declarations

  const navigate = useNavigate();
  const location = useLocation();
  
  const formData = useRef({ userId: '', code: '', submitted: false});
  

  logMonitoring(`formData.current: ${formData.current} ` , "ConfirmAccountForm()");

  //#endregion Declarations

  //#region Methods

   useEffect(() => {
    const params = new URLSearchParams(location.search);
    formData.current.userId = params.get('userId') || '';
    formData.current.code = params.get('code') || '';
    logMonitoring(`Extracted formData.current: ${formData.current} from URL`, "ConfirmAccountForm().useEffect()");

    if (formData.current.submitted === false) {
      formData.current.submitted = true;
      executeOnSubmit();
    }
  }, []);

  const executeOnSubmit = async () => {
    const { userId, code } = formData.current;
    //setLoading(true);

    const result = await confirmation(userId, code);
    logMonitoring(`userId: ${userId} code: ${code} result: ${result}`, "ConfirmAccountForm().executeOnSubmit()");
    //setLoading(false);

    if (result?.isOk) {
      logMonitoring(`navigate('/login')`, "ConfirmAccountForm().executeOnSubmit()");
      notify(result.message, 'success', 5 * 1000); //todo read from setting
    } else {
      notify(result.message, 'error', 5 * 1000); //todo read from setting
    }

    navigate('/login');
  };

  //#endregion Methods

  //#region Validations

  //#endregion Validations

  //#region HTML & Components
  
  return (
    <form className={'confirm-account-form'}>
      <Form disabled={true}>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={false}
          >
            <span className="dx-button-text">
              {
                <LoadIndicator width={'24px'} height={'24px'} visible={true} /> 
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );

  //#endregion  HTML & Components
}

//#region Declarations

//#endregion Declarations