import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';

import { CardAuth } from '../../components/library/card-auth/CardAuth';

import type { SingleCardProps } from '../../types';

import './single-card.scss';
import { logMonitoring } from '../../api/monitoring';


export default function SingleCard({ title, description, children }: React.PropsWithChildren<SingleCardProps>) {
  logMonitoring(`title: ${title} description: ${description}` , "SingleCard()");
  return (
    <ScrollView height={'100%'} width={'100%'} className='view-wrapper-scroll single-card'>
      <CardAuth title={title} description={description}>
        {children}
      </CardAuth>
    </ScrollView>
)}
