//#region Imports

import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm, ConfirmAccountForm, Confirm2FA } from './components';
import { logMonitoring } from './api/monitoring';

//#endregion Imports

//#region HTML & Components

export default function UnauthenticatedContent() {

  logMonitoring(`` , "UnauthenticatedContent()");
  return (
    <Routes>
      <Route
        path='/login' 
        element={
          <SingleCard title="Sign in">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path='/create-account'
        element={
          <SingleCard title="Sign up">
            <CreateAccountForm />
          </SingleCard>
        }
      />
      <Route
        path='/confirm-account'
        element={
          <SingleCard title="Confirm acount">
            <ConfirmAccountForm />
          </SingleCard>
        }
      />
       <Route
        path='/confirm-2fa'
        element={
          <SingleCard title="Confirm two factor authentication">
            <Confirm2FA />
          </SingleCard>
        }
      />
      <Route 
        path='/reset-password'
        element={
          <SingleCard
            title="Reset Password"
            description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/change-password'
        element={
          <SingleCard title="Change password">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}

//#endregion HTML & Components