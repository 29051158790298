//#region Imports

import React, { useState, useRef, useCallback } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { ValidationCallbackData } from 'devextreme-react/common';
import { changePassword } from '../../api/ServerCalls/Public/Account/Account';
import { logMonitoring } from '../../api/monitoring';

//#endregion Imports

//#region Methods

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ userId: '', code: '', submitted: false, password: '' });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  formData.current.userId = params.get('userId') || '';
  formData.current.code = params.get('code') || '';
  logMonitoring(`Extracted formData.current: ${formData.current} from URL`, "ChangePasswordForm().onSubmit()");

  logMonitoring(`navigate: ${navigate} loading: ${loading} formData: ${formData} ` , "ChangePasswordForm()");

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const result = await changePassword(formData.current.userId, formData.current.code, formData.current.password);
    logMonitoring(`formData.current: ${formData.current} result: ${result}` , "ChangePasswordForm().changePassword()");
    setLoading(false);

    if (result.isOk) {
      notify(result.message, 'success', 2000);
      logMonitoring(`navigate('/login')` , "ChangePasswordForm().changePassword()");
      navigate('/login');
    } else {
      notify(result.message, 'error', 2000);
    }
  }, [navigate]);

  const anyInputMaxLength = useCallback(
    ({ value }: ValidationCallbackData) => value?.length <= 256 ?? false,
    []
  );

  const anyInputMinLength = useCallback(
    ({ value }: ValidationCallbackData) => value?.length >= 6 ?? false,
    []
  );

  const anyInputMinOneAlphaNumeric = useCallback(
    ({ value }: ValidationCallbackData) => /\W|_/.test(value ?? '') === true,
    []
  );
  
  const anyInputMinOneDigit = useCallback(
    ({ value }: ValidationCallbackData) => /\d/.test(value ?? '') === true,
    []
  );

  const anyInputMinOneUpperCase = useCallback(
    ({ value }: ValidationCallbackData) => /[A-Z]/.test(value ?? '') === true,
    []
  );
  
  const confirmPassword = useCallback(
    ({ value }: ValidationCallbackData) => value === formData.current.password,
    []
  );

  //#endregion Methods

  //#region HTML & Components

  return (
    <form className={'change-password-form'}onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords must be at least 6 characters'}
            validationCallback={anyInputMinLength}
          />
          <CustomRule
            message={'Passwords must have at least one non alphanumeric character'}
            validationCallback={anyInputMinOneAlphaNumeric}
          />
          <CustomRule
            message={'Passwords must have at least one digit (\'0\'-\'9\')'}
            validationCallback={anyInputMinOneDigit}
          />
          <CustomRule
            message={'Passwords must have at least one uppercase (\'A\'-\'Z\')'}
            validationCallback={anyInputMinOneUpperCase}
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Continue'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };

//#endregion HTML & Components