import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import './EnumsNotificationCategory.scss';

import DataGrid, { Sorting, Selection, HeaderFilter,
   Scrolling, SearchPanel, ColumnChooser, Export,
    Column, Toolbar, Item, LoadPanel, DataGridTypes
  , ColumnFixing, FilterRow, GroupPanel, RequiredRule,
  Editing
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { DynamicGet } from '../../utils/DynamicGet';
import { getApi } from '../../api/ServerCalls/Authorized/Generic/Dynamic';

const exportFormats = ['xlsx', 'pdf'];

export const EnumsNotificationCategory = () => {
  const [gridDataSource, setGridDataSource] = useState(new DataSource({
    store: new ArrayStore({
      data: [],
      key: 'Id'
    }),
    paginate: true,
    pageSize: 10
  }));
  
  const [isLoading, setIsLoading] = useState(false);
  const [dbDynamicGet, setDbDynamicGet] = useState(new DynamicGet());

  const dataGridRef = useRef<any>(null);
  
  useMemo(() => {
    dbDynamicGet.page = '31';
    dbDynamicGet.pageSize = 25;
    dbDynamicGet.pageIndex = 1;
  }, [dbDynamicGet]);

  const refresh = useCallback(async () => {
    //setIsLoading(true);
    if (dataGridRef.current) {
      dataGridRef.current.instance.beginCustomLoading("Loading...");
    }
    try {

      const result = await getApi(dbDynamicGet);
  
      if (result.isOk && result.data && Array.isArray(result.data)) {
        setGridDataSource(new DataSource({
          store: new ArrayStore({
            data: result.data,
            key: 'Id'
          }),
          paginate: true,
          pageSize: 10
        }));
      } else {
        console.error('Failed to fetch data:', result.data.message || "Data received is not an array.");
      }
    } catch (error) {
      console.error('Error fetching database details:', error);
    }
    //setIsLoading(false);
    if (dataGridRef.current) {
      dataGridRef.current.instance.endCustomLoading();
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  
const cellIdRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Id'>
      <div>{cell.data.Id}</div>
    </div>
  );


const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Name'>
      <div>{cell.data.Name}</div>
    </div>
  );


const cellDescriptionRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
    <div className='Description'>
      <div>{cell.data.Description}</div>
    </div>
  );

return (
    //todo changename
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          ref={dataGridRef}
          id="dataGrid"
          className='grid theme-dependent'
          columnAutoWidth={true}
          allowColumnReordering={true}
          showBorders={true}
          keyExpr="Id"
          noDataText=''
          focusedRowEnabled={false}
          height='100%'
          dataSource={gridDataSource}
          //onRowClick={onRowClick}
          //onExporting={onExporting}
          //ref={gridRef}
        >
          <ColumnFixing enabled={true}/>
          <ColumnChooser enabled={true} />
          <Sorting mode='multiple' />
          <FilterRow visible={true} />
          <SearchPanel visible={false} />
          <GroupPanel visible={true} />
          <HeaderFilter visible ={true}/>
          <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true}
                />


          <LoadPanel 
          showPane={true}
          showIndicator={true}
          enabled={true} />
          {/* <SearchPanel visible placeholder='Contact Search' /> */}
          <Export enabled allowExportSelectedData formats={exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='none'
            mode='multiple'
          />
          <Scrolling mode='virtual' />
          <Toolbar>
            <Item location='before'>
              <div className='grid-header'>System databases</div>
            </Item>
            <Item
              location='after'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='exportButton' />
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='columnChooserButton' locateInMenu='auto' />
            <Item name='searchPanel' locateInMenu='auto' />
          </Toolbar>      
<Column
            dataField='Id'
            caption='Id'
            
            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellIdRender}
          />

<Column
            dataField='Name'
            caption='Name'
            
            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellNameRender}
          />

<Column
            dataField='Description'
            caption='Description'
            
            fixed={undefined}
            visible={undefined}
            defaultSortOrder={undefined}
            groupIndex={undefined}

            //sortOrder='asc'
            hidingPriority={2}
            minWidth={150}
            cellRender={cellDescriptionRender}
          />
           </DataGrid>
      </div>
    </div>
  );
};
