//#region Imports

import axios, { AxiosError } from 'axios';
import { sendPost, sendGet, sendDelete, sendPatch, sendPut } from '../../../Helpers'
import { ApiResponse } from '../../../../types/api-response';
import { DynamicGet } from '../../../../utils/DynamicGet'; 

//#endregion Imports

//#region Methods


export async function readRoles(): Promise<ApiResponse> 
{
   try
   {
      return await sendPost('/aspnet/readRoles', { });
   }
   catch { 
      return { isOk: false, message: "Server error", total: 0 };
   }
}


export async function readRoleClaims(): Promise<ApiResponse> 
{
   try
   {
      return await sendPost('/aspnet/readRoleClaims', { });
   }
   catch { 
      return { isOk: false, message: "Server error", total: 0 };
   }
}

//#endregion Methods
