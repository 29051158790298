//#region Imports

import { HomeHomePage, EnumsEnvironmentPage, EnumsDeployPage, EnumsCrudPage, EnumsPagePage, EnumsMessageGravityPage, EnumsMessageSourcePage, EnumsPageActionPage, EnumsTaskPage, EnumsTaskPeriodicityPage, EnumsNotificationPage, EnumsNotificationCategoryPage, EnumsTemplateTypePage, EnumsMovementPage, EnumsPaymentPage, EnumsProductPage, EnumsProductStatusPage, EnumsReasonPage, MaintenanceTasksPage, AdminSystemSettingsPage, AdminSystemPartitionsPage, AdminDatabasesPage, AdminTablesPage, AdminApisPage, AdminOsInfoPage, AdminLegalVatsPage, GlobalCountriesPage, GlobalCurrenciesPage, GlobalFlagsPage, GlobalLanguagesPage, GlobalTimezonesPage, TranslationLanguagesPage, TranslationControlsPage, TranslationTextsPage, UiMenuItemsPage, UiPagesPage, UiComponentsPage, UiControlsPage, DebugTracePage, AspnetRolesPage, AspnetRoleClaimsPage, AspnetUsersPage, AspnetUserClaimsPage, AspnetUserLoginsPage, AspnetUserTokensPage, TenantTenantsPage, TenantTenantSettingsPage, ClientClientsPage, ClientClientSettingsPage, NotificationTemplatesPage, NotificationNotificationsPage, MerchandiseStructuresPage, MerchandiseProductsPage, PriceCurrentPricesPage, PriceHistoryPricesPage, InventoryCurrentInventoriesPage, InventoryHistoryInventoriesPage, AuditLogsPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

//#endregion Imports

//#region HTML & Components

const routes = [
    {
path: '/Home-Home',
element: HomeHomePage 
},
    {
path: '/Enums-Environment',
element: EnumsEnvironmentPage 
},
    {
path: '/Enums-Deploy',
element: EnumsDeployPage 
},
    {
path: '/Enums-Crud',
element: EnumsCrudPage 
},
    {
path: '/Enums-Page',
element: EnumsPagePage 
},
    {
path: '/Enums-MessageGravity',
element: EnumsMessageGravityPage 
},
    {
path: '/Enums-MessageSource',
element: EnumsMessageSourcePage 
},
    {
path: '/Enums-PageAction',
element: EnumsPageActionPage 
},
    {
path: '/Enums-Task',
element: EnumsTaskPage 
},
    {
path: '/Enums-TaskPeriodicity',
element: EnumsTaskPeriodicityPage 
},
    {
path: '/Enums-Notification',
element: EnumsNotificationPage 
},
    {
path: '/Enums-NotificationCategory',
element: EnumsNotificationCategoryPage 
},
    {
path: '/Enums-TemplateType',
element: EnumsTemplateTypePage 
},
    {
path: '/Enums-Movement',
element: EnumsMovementPage 
},
    {
path: '/Enums-Payment',
element: EnumsPaymentPage 
},
    {
path: '/Enums-Product',
element: EnumsProductPage 
},
    {
path: '/Enums-ProductStatus',
element: EnumsProductStatusPage 
},
    {
path: '/Enums-Reason',
element: EnumsReasonPage 
},
    {
path: '/Maintenance-Tasks',
element: MaintenanceTasksPage 
},
    {
path: '/Admin-SystemSettings',
element: AdminSystemSettingsPage 
},
    {
path: '/Admin-SystemPartitions',
element: AdminSystemPartitionsPage 
},
    {
path: '/Admin-Databases',
element: AdminDatabasesPage 
},
    {
path: '/Admin-Tables',
element: AdminTablesPage 
},
    {
path: '/Admin-Apis',
element: AdminApisPage 
},
    {
path: '/Admin-OsInfo',
element: AdminOsInfoPage 
},
    {
path: '/Admin-LegalVats',
element: AdminLegalVatsPage 
},
    {
path: '/Global-Countries',
element: GlobalCountriesPage 
},
    {
path: '/Global-Currencies',
element: GlobalCurrenciesPage 
},
    {
path: '/Global-Flags',
element: GlobalFlagsPage 
},
    {
path: '/Global-Languages',
element: GlobalLanguagesPage 
},
    {
path: '/Global-Timezones',
element: GlobalTimezonesPage 
},
    {
path: '/Translation-Languages',
element: TranslationLanguagesPage 
},
    {
path: '/Translation-Controls',
element: TranslationControlsPage 
},
    {
path: '/Translation-Texts',
element: TranslationTextsPage 
},
    {
path: '/Ui-MenuItems',
element: UiMenuItemsPage 
},
    {
path: '/Ui-Pages',
element: UiPagesPage 
},
    {
path: '/Ui-Components',
element: UiComponentsPage 
},
    {
path: '/Ui-Controls',
element: UiControlsPage 
},
    {
path: '/Debug-Trace',
element: DebugTracePage 
},
    {
path: '/Aspnet-Roles',
element: AspnetRolesPage 
},
    {
path: '/Aspnet-RoleClaims',
element: AspnetRoleClaimsPage 
},
    {
path: '/Aspnet-Users',
element: AspnetUsersPage 
},
    {
path: '/Aspnet-UserClaims',
element: AspnetUserClaimsPage 
},
    {
path: '/Aspnet-UserLogins',
element: AspnetUserLoginsPage 
},
    {
path: '/Aspnet-UserTokens',
element: AspnetUserTokensPage 
},
    {
path: '/Tenant-Tenants',
element: TenantTenantsPage 
},
    {
path: '/Tenant-TenantSettings',
element: TenantTenantSettingsPage 
},
    {
path: '/Client-Clients',
element: ClientClientsPage 
},
    {
path: '/Client-ClientSettings',
element: ClientClientSettingsPage 
},
    {
path: '/Notification-Templates',
element: NotificationTemplatesPage 
},
    {
path: '/Notification-Notifications',
element: NotificationNotificationsPage 
},
    {
path: '/Merchandise-Structures',
element: MerchandiseStructuresPage 
},
    {
path: '/Merchandise-Products',
element: MerchandiseProductsPage 
},
    {
path: '/Price-CurrentPrices',
element: PriceCurrentPricesPage 
},
    {
path: '/Price-HistoryPrices',
element: PriceHistoryPricesPage 
},
    {
path: '/Inventory-CurrentInventories',
element: InventoryCurrentInventoriesPage 
},
    {
path: '/Inventory-HistoryInventories',
element: InventoryHistoryInventoriesPage 
},
    {
path: '/Audit-Logs',
element: AuditLogsPage 
},

];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});

//#endregion HTML & Components